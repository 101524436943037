<template>
  <div
    class="co-flex-col pa-4"
  >
    <v-row :dense="dense">
      <template
        v-if="items.length"
      >
        <v-col
          v-for="(item, i) in items"
          :key="`wk_${i}`"
          cols="12"
          lg="3"
          md="4"
          sm="6"
        >
          <v-hover
            v-slot="{ hover }"
            :disabled="!hovered"
            close-delay="120"
          >
            <v-card
              :elevation="hover ? hoverElevation : normalElevation"
              :class="[round, 'transition-swing']"
              :color="backColor"
              flat
              tile
            >
              <v-img
                v-if="item.cover"
                :aspect-ratio="ratio"
                :src="item.cover"
                height="inherit"
              >
                <div class="co-flex-col co-justify-between co-h-full">
                  <div
                    class="co-flex-col co-w-full co-flex-grow "
                    @click="toContent(i, item)"
                  />
                  <div
                    v-if="hover"
                    class="co-flex-row co-justify-between co-items-center grey darken-4 co-h40 px-4"
                    style="opacity: 0.70;"
                  >
                    <span
                      class="text-body-1 white--text"
                      @click="toContent(i, item)"
                    >
                      {{ item.title }}
                    </span>
                    <div class="co-flex-row co-items-center">
                      <div
                        class="co-flex-row co-items-center"
                        style="cursor: pointer;"
                        @click="onLike(i, item)"
                      >
                        <v-icon
                          size="20"
                          color="white"
                        >
                          {{ icos.like }}
                        </v-icon>
                        <span class="text-body-1 white--text ml-1">
                          {{ item.likesNum }}
                        </span>
                      </div>

                      <v-icon
                        size="20"
                        color="white"
                        class="ml-3"
                        @click="onAlbum(i, item)"
                      >
                        {{ icos.album }}
                      </v-icon>
                    </div>
                  </div>
                </div>
              </v-img>
            </v-card>
          </v-hover>
        </v-col>
      </template>
      <template v-else>
        <v-col
          cols="12"
        >
          <div class="co-flex-col co-justify-center co-items-center pa-6">
            <span
              class="co-text-md co-text-gray-500"
            >
              当前用户还没上传作品
            </span>
          </div>
        </v-col>
      </template>
    </v-row>

    <div
      v-if="pagination.length"
      class="d-flex flex-row mt-6 mb-8"
    >
      <v-pagination
        v-model="pagination.current"
        :length="pagination.length"
        :total-visible="pagination.totalVisible"
        @input="changePagination($event)"
      />
    </div>

    <my-works-albums ref="dlgAlbums" />
    <dialog-alert ref="dlgAlert" />
  </div>
</template>

<script>
  import api from '@/api/co.api'
  import app from '@/api/co.app'
  import user from '@/api/co.user'
  import web from '@/api/web/co.web'

  import mixPreset from '@/pages/mixins/my/mix.my.preset'

  const parts = mixPreset.Parts
  const names = mixPreset.Names
  const part = parts[names.WORKS]

  export default {
    components: {
      MyWorksAlbums: () => import('@/pages/sections/my/MyWorksAlbums.vue'),
      DialogAlert: () => import('@/pages/sections/comm/DialogAlert.vue')
    },
    props: {
      userId: {
        type: String,
        default: ''
      },
      backColor: {
        type: String,
        default: 'white'
      },
      round: {
        type: String,
        default: 'rounded'
      },
      margin: {
        type: String,
        default: 'ma-0'
      },
      hovered: {
        type: Boolean,
        default: true
      },
      dense: {
        type: Boolean,
        default: true
      },
      perColumns: {
        type: [Number, String],
        default: 3
      },
      normalElevation: {
        type: [Number, String],
        default: 0
      },
      hoverElevation: {
        type: [Number, String],
        default: 18
      }
    },
    data () {
      return {
        name: part.name,
        label: part.label,
        title: part.title,
        total: 0,
        type: part.type,
        ratio: part.ratio,
        params: null,
        items: [],
        pagination: {
          current: 1,
          length: 0,
          totalVisible: 7
        },
        btnDel: {
          icon: 'mdi-close'
        },
        icos: {
          like: 'mdi-thumb-up-outline',
          album: 'mdi-folder-plus-outline'
        },
        dlgAlbum: {
          title: '添加到影集',
          visible: false,
          width: '450px'
        },
        perCols: {
          c1: {
            sm: 12,
            md: 12
          },
          c2: {
            sm: 12,
            md: 6
          },
          c3: {
            sm: 6,
            md: 4
          },
          c4: {
            sm: 6,
            md: 3
          },
        },
      }
    },
    computed: {
      smCols () {
        const obj = this.perCols[`c${this.perColumns}`] || {
          sm: 6,
          md: 4
        }
        return obj.sm
      },
      mdCols () {
        const obj = this.perCols[`c${this.perColumns}`] || {
          sm: 6,
          md: 4
        }
        return obj.md
      },
    },
    watch: {
      userId: {
        handler (val) {
          this.configParams(val)
          if (val) {
            this.loadContents(true)
          }
        },
        immediate: true
      }
    },
    created () {
    },
    methods: {
      showAlert (type, message) {
        const dlg = this.$refs.dlgAlert
        if (dlg) {
          dlg.show({
            type,
            message
          })
        }
      },
      toLogin () {
        const params = {
          mode: 'phone'
        }
        api.page.navigate(this, 'login', params)
      },
      toAlbum (index, item) {
        const dlg = this.$refs['dlgAlbums']
        if (dlg) {
          dlg.show(item)
        }
      },
      toLike (index, item) {
        const me = this
        const executed = function (res) {
          // console.log({ res })
          if (res.status) {
            let num = parseInt(item.likesNum, 10) || 0
            if (num < 0) {
              num = 0
            }
            num += 1
            me.$set(item, 'likesNum', num)
            me.showAlert('success', '点赞成功')
          } else {
            const err = res.data || {}
            if (err.code === 1001) {
              me.showAlert('info', '再次点赞')
            } else {
              me.showAlert('error', '点赞失败')
            }
          }
        }

        web.behaviour.like({
          contentId: item.contentId,
          executed
        })
      },
      onAlbum (index, item) {
        const me = this
        const callback = function (res) {
          if (res.status) {
            me.toAlbum(index, item)
          } else {
            me.$confirm('登录后才能将图片加入到自己的影集, 是否现在登录?', '登录提示', {
              confirmButtonText: '是',
              cancelButtonText: '否',
              type: 'info'
            }).then(() => {
              me.toLogin()
            }).catch(() => {
              me.$message({
                type: 'info',
                message: '已取消登录！'
              })
            })
          }
        }

        user.login.isLogined(callback)
      },
      onLike (index, item) {
        const me = this
        const callback = function (res) {
          if (res.status) {
            me.toLike(index, item)
          } else {
            me.$confirm('登录后才能点赞, 是否现在登录?', '登录提示', {
              confirmButtonText: '是',
              cancelButtonText: '否',
              type: 'info'
            }).then(() => {
              me.toLogin()
            }).catch(() => {
              me.$message({
                type: 'info',
                message: '已取消登录！'
              })
            })
          }
        }

        user.login.isLogined(callback)
      },
      toContent (index, item) {
        const params = {
          contentId: item.contentId,
          type: item.type,
          title: item.title
        }

        api.page.navigate(this, 'hotDetail', params)
      },
      changePagination (number) {
        const params = this.params
        const data = this.params.data
        const index = parseInt(number, 10) || 1

        this.pagination.current = index
        data.currentPage = index
        params.reload = true
        api.httpx.getItems(params)
      },
      configItems () {
        this.items = []
        for (const i in this.params.items) {
          const src = this.params.items[i] || {}
          const thumbs = src.thumbs || []
          for (const j in thumbs) {
            const cover = thumbs[j]
            const dst = {
              key: `img-${i}-${j}`,
              contentId: src.contentId,
              categoryId: src.categoryId,
              circleId: src.circleId,
              title: src.title,
              cover,
              type: src.type,
              likesNum: src.likesNum,
              collectNum: src.collectNum,
              hitNum: src.hitNum,
              forwardedNum: src.forwardedNum,
              commentNum: src.commentNum
            }

            this.items.push(dst)
          }
        }
      },
      configParams (userId) {
        const me = this
        if (me.params) {
          me.params.data.userId = userId
        } else {
          const executed = function (res) {
            if (res.status) {
              me.total = res.total
              me.title = `${me.label} ${res.total}`
              app.emit(app.event.USER_WORKS_GET, {
                name: me.name,
                title: me.title,
                label: me.label,
                total: res.total
              })
              const data = me.params.data
              const pagination = me.pagination
              const pageSize = parseInt(data.pageSize) || 9
              pagination.length = Math.ceil(res.total / pageSize)
              me.configItems()
            }
          }

          me.params = web.content.getParams({
            userId,
            type: me.type,
            accessScope: web.comm.Scopes.PUBLIC,
            state: web.comm.States.PUBLISH,
            executed
          })
        }
      },
      loadContents (reload = true) {
        this.params.reload = reload
        api.httpx.getItems(this.params)
      },
    }
  }
</script>
